Core.Carousel = Garnish.Base.extend(
{
    Selector: {
        CAROUSEL: '.b-carousel'
    },

    initialized : false,

    init: function(settings)
    {
        var self = this;

        Core.debug && console.log('Core.Carousel.init() invoked');

        this.setSettings(settings, Core.Carousel.defaults);

        if (this.initialized)
        {
            return;
        }

        this.initialized = true;

        this.setup();
    },

    setup : function()
    {
        $('.b-carousel').owlCarousel({
            autoPlay : this.settings.autoPlay,
            items : 3,
            itemsDesktop : [1170, 3],
            itemsDesktopSmall : [1024, 3],
            itemsTabletSmall : [768, 2],
            itemsMobile : [480, 1],
            navigation : true,
            pagination : false,
            navigationText : ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"]
        });

    }
},
{
    defaults: {
        autoPlay: 5000
    }
});
