/** global: Core */
/** global: Garnish */

$.extend(Core,
    {
        // define containers
        $pageWrapper: $('.bc-page-wrapper'),

        initialized: false,

        debug: false,

        breakPoints: {
            'xs' : 0,
            'sm' : 768,
            'md' : 992,
            'lg' : 1200
        },

        currentBreakPoint: 'xs',

        initialize: function()
        {
            if (this.initialized)
            {
                return;
            }

            this.initialized = true;

            new Core.Appointments;
            new Core.AppointmentValidation;
            new Core.Custom;
            new Core.Validation;
            new Core.RevolutionSlider;
            new Core.HeaderSearch;
            new Core.Carousel;
            new Core.Colorbox;
            new Core.MobileMenuToggle;
            new Core.MobileMenu;
            Core.FactCounter.bind();
        },

        /**
         * Detect CSS transition event
         * @returns {*}
         */
        whichTransitionEvent: function() {

            var t,
                el = document.createElement("fakeelement");

            var transitions = {
                "transition"      : "transitionend",
                "OTransition"     : "oTransitionEnd",
                "MozTransition"   : "transitionend",
                "WebkitTransition": "webkitTransitionEnd"
            };

            for (t in transitions){
                if (el.style[t] !== undefined){
                    return transitions[t];
                }
            }
        },

        /**
         * Detect CSS animation event
         * @returns {*}
         */
        whichAnimationEvent: function() {

            var t,
                el = document.createElement("fakeelement");

            var animations = {
                "animation"      : "animationend",
                "OAnimation"     : "oAnimationEnd",
                "MozAnimation"   : "animationend",
                "WebkitAnimation": "webkitAnimationEnd"
            };

            for (t in animations){
                if (el.style[t] !== undefined){
                    return animations[t];
                }
            }
        }
    });


// -------------------------------------------
//  Custom jQuery plugins
// -------------------------------------------
$.extend($.fn, {

    dummy: function()
    {
        return this.each(function() {
            var $elem = $(this);
        });
    }

});

Garnish.$doc.ready(function()
{
    Core.initialize();
});
