Core.HeaderSearch = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.HeaderSearch.init() invoked');

        this.setSettings(settings, Core.HeaderSearch.defaults);

        var $container = $('.bc-header-search'),
            $searchFormToggle = $('.bc-header-search-toggle'),
            searchEl = $container.find('.bc-header-search-popover'),
            searchSubmit = searchEl.find('button'),
            $searchForm = $container.find('form');


        Garnish.$doc.on('click', function(e)
        {
            if ($(e.target).closest($container).length === 0)
            {
                searchEl.removeClass('show');
            }
        });

        // Register button action
        $searchFormToggle.on('click', function(e)
        {
            e.preventDefault();

            searchEl.addClass('show');

            setTimeout(function ()
            {
                searchEl.find('input').focus();
            }, 250);
        });

        // Validate the search input: q is required
        // $searchForm.validate({
        //     rules: {
        //         q: {
        //             required: true
        //         }
        //     },
        //     // errorPlacement: function (error, element)
        //     // {
        //     //
        //     // },
        //     highlight: function (element) {
        //         $(element)
        //             .closest(".control-group")
        //             .removeClass("success")
        //             .addClass("error");
        //     },
        //     success: function (element) {
        //         $(element)
        //             .closest(".control-group")
        //             .removeClass("error")
        //             .addClass("success");
        //     }
        // });

        searchSubmit.on("click", function()
        {
            $searchForm.submit();
        });
    }
},
{
    defaults: {}
});
