Core.Colorbox = Garnish.Base.extend(
{
    Selector: {
        TOGGLE: '.js-colorbox'
    },

    debug: false,
    initialized : false,

    init: function(settings)
    {
        var self = this;

        (Core.debug || this.debug) && console.log('Core.Colorbox.init() invoked');

        this.setSettings(settings, Core.Colorbox.defaults);

        if (this.initialized)
        {
            return;
        }

        this.initialized = true;

        this.setup();
    },

    setup : function()
    {
        $(this.Selector.TOGGLE).colorbox({
            //opacity: this.settings.opacity
        });
    }
},
{
    defaults: {
        // opacity: 0.5
    }
});
