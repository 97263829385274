;(function(Garnish, Core, $, win, doc, undefined)
{
    "use strict";

    const name  = 'factcounter';

    const ClassName = {
    };

    const Selector = {
        CONTAINER: '.js-' + name,
    };

    Core.FactCounter = Garnish.Base.extend(
    {
        debug: false,
        resizeTimeout: null,

        /**
         * Constructor.
         *
         * @param el
         * @param settings
         */
        init(el, settings)
        {
            this.debug && console.log('Core.FactCounter.init() invoked');

            //this.debug && console.log('Elements', $(el));

            this.$el      = $(el);
            let meta      = this.$el.data(name + '-opts');
            this.settings = $.extend(Core.FactCounter.defaults, settings, meta);

            this.$el.data(name, this);

            this.setup();

            //this.addListener(win, 'resize', 'handleWindowResize');
        },

        setup() {

            let me = this;

            me.$el.appear(function() {
                me.$el.each(function() {

                    let $counter = $(this),
                        countTo = $counter.attr("data-count"),
                        countFrom = $counter.text(),
                        countDuration = parseInt($counter.attr('data-duration')) * 1000;

                    me.debug && console.log('Counter', $counter, 'Count To', countTo, 'Count From', countFrom, 'Duration', countDuration);

                    $({countNum : countFrom}).animate({
                            countNum : countTo
                    }, {
                        duration : countDuration,
                        easing : "linear",
                        step : function() {
                            $counter.text(Math.floor(this.countNum))
                        },
                        complete : function() {
                            $counter.text(this.countNum)
                        }
                    })
                })
            })
        },

        /**
         * Handle the resizing of the window
         */
        handleWindowResize() {
            let me = this;

            if (this.resizeTimeout) return;

            this.setup();

            this.resizeTimeout = setTimeout(function () {
                me.resizeTimeout = null;
            }, 500);
        },

        destroy: function() {
            this.$el.off('.' + name);
            this.$el.find('*').off('.' + name);

            this.$el.removeData(name);
            this.$el = null;
        }

    },
    {
        /**
         * Default settings
         */
        defaults: {
        },

        /**
         * Convenient static method to initiate the module.
         *
         * @param settings
         */
        bind: function(settings) {
            $(Selector.CONTAINER).factcounter(settings);
        }
    });

    /**
     * Makes the module available as jQuery plugin.
     *
     * @param settings
     * @returns {*}
     */
    $.fn.factcounter = function(settings) {
        return this.each(function() {
            new Core.FactCounter(this, settings);
        });
    };

})(Garnish, Core, jQuery, window, document, void 0);
